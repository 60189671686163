import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Game - Name That Hollywood Smile | The Smile Stylist" 
    description="Can you name these perfect Hollywood smiles?  You too can have the celebrity smile of your dreams, all you need is to take the first step and book your free consult today." 
    keywords="best smiles, dentist blog, teeth canada, dental clinics, oakville, toronto, mississauga, canada" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="blog hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="blog-news-art">
              <h1>Game: Name That Hollywood Smile!</h1>
              <h2>Whose Stunning Teeth Are Those?!</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main-top-img" className="blog hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <p>&nbsp;</p>
        </div>
      </div>
    </section>
    <section id="top-mobile" className="hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h2>Game: Name That Hollywood Smile!</h2>
            <h2>Whose Stunning Teeth Are Those?!</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-10 wow animated fadeInRight">
            <p className="lead">Hollywood is chockfull of the world’s most beautiful smiles. That’s right… in LA where the sun shines, the smiles shine even brighter!</p>

            <p>So this month, our Smile Stylist wants to know how well you know these six celebrity grins that have surely been graced by cosmetic dentistry.</p>

            <p>Give it your best shot!</p>

            <p>1. This right here is no 70’s smile – this Ukraine-born beauty has us completely mesmerized when she flashes those pearly whites.</p>
            <img alt="Mila" src={ require("../../images/desktop/_blog/pics/hollywood-smiles/milakunis.jpg" )} className="bl__imga"/>

            <p>2. We doubt this man was “Bourne” with this sexy smirk… and if he was, he won the genetic dental lottery, no doubt!</p>
            <img alt="Matt Damon" src={ require("../../images/desktop/_blog/pics/hollywood-smiles/Matt Damon.jpg" )} className="bl__imga"/>

            <p>3. This beautiful and talented lady is not only a fierce dancer, but she’s got a celebrity smile that makes heads turn! (Oh, and guess who’s designed that gorgeous smile? You guessed it: our Smile Stylist!!!)</p>
            <img alt="Peta" src={ require("../../images/desktop/_blog/pics/hollywood-smiles/Peta.jpg" )} className="bl__imga"/>

            <p>4. This New Jersey-born babe has a smile that’s truly out of this world… might we say, the world of Pandora? (This would be the big giveaway).</p>
            <img alt="Zoe" src={ require("../../images/desktop/_blog/pics/hollywood-smiles/Zoe Zaldana.jpg" )} className="bl__imga"/>

            <p>5. We don’t know about you, but this Hollywood smile totally ROCKs our world… (oh, and isn’t it ironic that he’s played a TOOTH FAIRY?!) We still find that to be quite hilarious.</p>
            <img alt="The Rock" src={ require("../../images/desktop/_blog/pics/hollywood-smiles/The Rock.jpg" )} className="bl__imga"/>

            <p>6. While this big-screen bombshell may have been in Fantastic Four, we’d say her true superpowers lie in her Fantastic Twenty-Eight! (No, we didn’t include wisdom teeth in that count – sorry!).</p>
            <img alt="Jessica Alba" src={ require("../../images/desktop/_blog/pics/hollywood-smiles/Jessica Alba.jpg" )} className="bl__imga"/>
            <p>What’s that out of the… you know, Waters?! While it’s arguable this wild spirit makes her grand entrances as a shark and not a dolphin (at the end of the day, we don’t care) – whatever that sea creature is: her smile is entrancing!</p>

            <p>Well, how’d you do? Don’t be shy to let us know!</p>

            <b>Answer Key:</b>
            <ol>
              <li>Mila Kunis</li>
              <li>Matt Damon</li>
              <li>Pita Murgatroyd</li>
              <li>Zoe Saldana</li>
              <li>Dwayne “The Rock” Johnson</li>
              <li>Jessica Alba</li>
            </ol>
          </div>

        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage